<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <operacioncrm-form
          :id="routeParams.idoperacioncrm"
          :es-tecnico="!!usuarioIdtecnico"
          :es-comercial="!!usuarioIdcomercial"
          :es-vigilante="!!usuarioIdvigilante"
          @change-values="changeFormData"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import OperacioncrmForm from '../components/OperacioncrmForm'
import Data from './OperacioncrmEditData'
import { get } from 'vuex-pathify'

export default {
  components: {
    OperacioncrmForm
  },
  mixins: [formPageMixin],
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdcomercial: get('usuario/idcomercial'),
    usuarioIdvigilante: get('usuario/idvigilante'),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Modificar operación'
    },
    changeFormData (formData) {
      this.formData = formData
      this.title = `Modificar operación ${formData.serie_numero}`
    },
    async submitForm () {
      await Data.update(
        this, this.routeParams.idoperacioncrm, this.formData
      )
      await this.$dirty.modified(this.$dirty.ENTITIES.remote.operacionCRM, this.routeParams.idoperacioncrm)
      this.$appRouter.go(-1)
    },
  },
}
</script>
